"use client";

import { getUsersHref } from "@/api/users/helpers";
import { ShortUser } from "@/api/users/types";
import { Tooltip } from "@/app/(components)/Basic/Tooltip";
import useMe from "@/shared/hooks/useMe";
import Link from "next/link";

export interface Props {
  artist: ShortUser;
  children: React.ReactNode;
  className?: string;
  tooltipPosition?: ["left" | "center" | "right", "top" | "middle" | "bottom"];
}

const UserLink = ({ artist, children, className, tooltipPosition }: Props) => {
  const { me } = useMe();
  return artist.hideProfile && me?.id !== artist.id ? (
    <Tooltip
      className={className}
      position={tooltipPosition}
      tooltipClassName={"w-36"}
      text="This user profile is hidden"
    >
      {children}
    </Tooltip>
  ) : (
    <Link href={getUsersHref(artist)} className={className}>
      {children}
    </Link>
  );
};

export default UserLink;
