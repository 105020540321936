import React from "react";
import { twMerge } from "tailwind-merge";
import { StaticImageData } from "next/image";
import DefaultProfile from "@/assets/graphics/default-profile.svg?url";
import { ImageTypeHandler } from "../ImageTypeHandler";
import { ImagePlaceholder } from "../Image/types";

export interface Props {
  className?: string;
  imgClassName?: string;
  image?: Partial<StaticImageData>;
  imagePlaceholder?: ImagePlaceholder;
  name?: string;
  rounded?: boolean;
  small?: boolean;
}

const UserImage = ({
  className,
  imgClassName,
  name,
  image,
  imagePlaceholder,
  rounded = true,
  small,
}: Props) => {
  return (
    <div className={twMerge(small ? "w-10 h-10" : "w-20 h-20", className)}>
      <ImageTypeHandler
        className={twMerge(rounded && "rounded-full", imgClassName)}
        src={image?.src || DefaultProfile.src}
        placeholder={imagePlaceholder}
        width={
          image?.width && image?.height ? image.width : DefaultProfile.width
        }
        height={
          image?.width && image?.height ? image.height : DefaultProfile.height
        }
        alt={name || "img"}
      />
    </div>
  );
};

export default UserImage;
