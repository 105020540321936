import(/* webpackMode: "eager", webpackExports: ["Hydrate","useHydrate"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/Hydrate.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/isRestoring.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientProvider","defaultContext","useQueryClient"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/QueryClientProvider.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/QueryErrorResetBoundary.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["unstable_batchedUpdates"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/reactBatchedUpdates.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useInfiniteQuery.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useIsFetching.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useIsMutating.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useMutation.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useQueries.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useQuery.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(components)/Application/Artists/ArtistItemsWithQuotes/ArtistItemsWithQuotes.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(components)/Application/Collections/CollectionSlider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(components)/Application/Hero/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(components)/Application/News/SmallNewsItemsList/SmallNewsItemsList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(components)/Application/User/UserLink/UserLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(components)/Basic/Button/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(components)/Basic/Graphic/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(components)/Basic/Icon/ThemedIcon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(components)/Basic/Player/Player.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/page.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/graphics/themed/light/etf-hero.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/arrow-right.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/coinbase.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/collections.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/latest-art-2.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/metamask.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/section-title.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/icons/walletConnect.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/images/artworkPlaceholder.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/web3/connectors/coinbase.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/web3/connectors/metaMask.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/web3/connectors/walletConnect.ts");
