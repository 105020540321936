"use client";
import { NoItems } from "@/app/(components)/Basic/NoItems";
import { ShortCollection } from "@/api/collections/types";
import { EnrichedObject } from "@/api/types";
import { User } from "@/api/users/types";
import { useCollectionSliderNfts } from "../../Nft/hooks/useCollectionSliderNfts";
import { NftSliderWithHeader } from "../../../Basic/NftSliderWithHeader";

export interface Props {
  collection: EnrichedObject<ShortCollection, "artist", User>;
  className?: string;
}
const CollectionSlider = ({ collection, className }: Props) => {
  const { data } = useCollectionSliderNfts(collection?.address);

  if (!collection || !Object.keys(collection).length || !data?.length) {
    return <NoItems message="No item to display." />;
  }
  return (
    <NftSliderWithHeader
      className={className}
      title={collection?.name}
      user={collection?.artist}
      description={collection?.description}
      buttonHref={`/collection/${collection?.slug}`}
      buttonText={`View collection`}
      images={
        data?.map((nft) => ({
          link: `/nft/${nft.id}`,
          src: nft.thumbnail ? nft.thumbnail : nft.image,
          width: nft.imageWidth,
          height: nft.imageHeight,
          alt: nft.name,
          mediaType: nft.mediaType,
        })) || []
      }
    />
  );
};

export default CollectionSlider;
