"use client";
import React from "react";
import { StaticImageData } from "next/image";
import { Button } from "../Button";
import ArrowRightIcon from "@/assets/icons/arrow-right.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { SwiperNavigationButton } from "./SwiperNavigationButton";
import { ContentWrapper } from "@/app/(components)/Wrappers/ContentWrapper";
import { twMerge } from "tailwind-merge";
import { User } from "@/api/users/types";
import { getUsersName } from "@/api/users/helpers";
import { UserImage } from "@/app/(components)/Basic/UserImage";
import Link from "next/link";
import { UserLink } from "../../Application/User/UserLink";
import { ImageTypeHandler } from "../ImageTypeHandler";
import { ImagePlaceholder } from "../Image/types";

export interface Props {
  title: string;
  description: string;
  buttonText: string;
  buttonHref: string;
  user?: User;
  className?: string;
  images: (StaticImageData & {
    link: string;
    alt: string;
    mediaType?: string;
    placeholder?: ImagePlaceholder;
  })[];
}

const NftSliderWithHeader = ({
  title,
  user,
  description,
  className,
  buttonHref,
  buttonText,
  images,
}: Props) => {
  return (
    <section className={twMerge("relative overflow-hidden", className)}>
      <ContentWrapper removePaddingOnMobile className="mb-10">
        <div className="flex lg:items-center items-start justify-between lg:flex-row flex-col mb-8 lg:mb-14">
          <div>
            <h3 className="gradient text-red text-h1-sm lg:text-h1 font-semibold tracking-tighter mb-4">
              {title}
            </h3>
            {user?.imageUrl && (
              <UserLink
                className="w-fit"
                artist={user}
                tooltipPosition={["right", "middle"]}
              >
                <div className="flex items-center text-secondary font-light tracking-tight text-4 leading-[19px] mb-6 gap-4 w-fit endemic:text-primary">
                  <UserImage
                    small
                    image={{ src: user.imageUrl }}
                    imagePlaceholder={user.imagePlaceholder}
                    name={getUsersName(user)}
                  />
                  <span>by {getUsersName(user)}</span>
                </div>
              </UserLink>
            )}
            <div className="text-primary font-light text-[20px] leading-[26px] mb-6 lg:mb-0 md:text-[32px] md:leading-[40px] line-clamp-3">
              {description}
            </div>
          </div>
          <div
            className={twMerge(
              `flex items-center lg:before:mr-8 lg:before:ml-8 lg:before:content-[''] lg:before:block lg:before:h-[1px] lg:before:w-20 lg:before:bg-invert endemic:lg:before:bg-primary`,
              description && "lg:mt-24",
            )}
          >
            <Button
              className={"whitespace-nowrap"}
              href={buttonHref}
              endIcon={
                <ArrowRightIcon className="fill-primary endemic:fill-invert w-4" />
              }
            >
              {buttonText}
            </Button>
          </div>
        </div>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={30}
          modules={[Pagination]}
          rewind={true}
          className="w-full h-[300px] lg:h-[500px] !pb-10 !overflow-visible mb-4 lg:mb-0"
        >
          <div
            className={twMerge(
              "flex items-center justify-center bottom:0 md:bottom-8 w-full -translate-y-full md:-translate-y-0 absolute z-10",
              images.length < 3 && "lg:hidden",
            )}
          >
            <SwiperNavigationButton />
            <SwiperNavigationButton isNext={true} />
          </div>
          {images.map((image, index) => (
            <SwiperSlide key={index} style={{ width: "auto" }}>
              <Link href={image.link}>
                <ImageTypeHandler
                  src={image.src}
                  alt={image.alt}
                  width={image.width}
                  height={image.height}
                  type={image.mediaType}
                  placeholder={image.placeholder}
                  className="w-auto h-full"
                />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </ContentWrapper>
    </section>
  );
};

export default NftSliderWithHeader;
